@charset "UTF-8";
/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #a41a1f;
  --bs-orange: #ea630f;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #d8d8d8;
  --bs-gray-dark: #6f4e37;
  --bs-gray-100: #f0f4fa;
  --bs-gray-200: #f2f3f5;
  --bs-gray-300: #f0f4fa;
  --bs-gray-400: #eff2f3;
  --bs-gray-500: #768693;
  --bs-gray-600: #d8d8d8;
  --bs-gray-700: #495057;
  --bs-gray-800: #6f4e37;
  --bs-gray-900: #707070;
  --bs-primary: #ed2224;
  --bs-secondary: #b0c4c2;
  --bs-light: #e8e2d7;
  --bs-success: #2edba6;
  --bs-gray-100: #f0f4fa;
  --bs-gray-200: #f2f3f5;
  --bs-gray-300: #f0f4fa;
  --bs-gray-400: #eff2f3;
  --bs-gray-500: #768693;
  --bs-gray-600: #d8d8d8;
  --bs-gray-800: #6f4e37;
  --bs-gray-900: #707070;
  --bs-info: #21aaff;
  --bs-warning: #ffc600;
  --bs-danger: #a41a1f;
  --bs-dark: #b1b1b1;
  --bs-black: #000000;
  --bs-red: #a41a1f;
  --bs-orange: #ea630f;
  --bs-primary-rgb: 237, 34, 36;
  --bs-secondary-rgb: 176, 196, 194;
  --bs-light-rgb: 232, 226, 215;
  --bs-success-rgb: 46, 219, 166;
  --bs-gray-100-rgb: 240, 244, 250;
  --bs-gray-200-rgb: 242, 243, 245;
  --bs-gray-300-rgb: 240, 244, 250;
  --bs-gray-400-rgb: 239, 242, 243;
  --bs-gray-500-rgb: 118, 134, 147;
  --bs-gray-600-rgb: 216, 216, 216;
  --bs-gray-800-rgb: 111, 78, 55;
  --bs-gray-900-rgb: 112, 112, 112;
  --bs-info-rgb: 33, 170, 255;
  --bs-warning-rgb: 255, 198, 0;
  --bs-danger-rgb: 164, 26, 31;
  --bs-dark-rgb: 177, 177, 177;
  --bs-black-rgb: 0, 0, 0;
  --bs-red-rgb: 164, 26, 31;
  --bs-orange-rgb: 234, 99, 15;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 39, 38, 38;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Roboto-Regular", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #272626;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 0.6667rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.33335rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1.125rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #ed2224;
  text-decoration: underline;
}
a:hover {
  color: #be1b1d;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #707070;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #d8d8d8;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.9375rem);
  padding-left: var(--bs-gutter-x, 0.9375rem);
  margin-right: auto;
  margin-left: auto;
}
.row {
  --bs-gutter-x: 2.133rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-sm,
.gx-sm {
  --bs-gutter-x: 0.33335rem;
}

.g-sm,
.gy-sm {
  --bs-gutter-y: 0.33335rem;
}

.g-3px,
.gx-3px {
  --bs-gutter-x: 3px;
}

.g-3px,
.gy-3px {
  --bs-gutter-y: 3px;
}

.g-15px,
.gx-15px {
  --bs-gutter-x: 15px;
}

.g-15px,
.gy-15px {
  --bs-gutter-y: 15px;
}

.g-25px,
.gx-25px {
  --bs-gutter-x: 25px;
}

.g-25px,
.gy-25px {
  --bs-gutter-y: 25px;
}

.g-35px,
.gx-35px {
  --bs-gutter-x: 35px;
}

.g-35px,
.gy-35px {
  --bs-gutter-y: 35px;
}

.g-n15,
.gx-n15 {
  --bs-gutter-x: -15px;
}

.g-n15,
.gy-n15 {
  --bs-gutter-y: -15px;
}

.g-n1,
.gx-n1 {
  --bs-gutter-x: -10px;
}

.g-n1,
.gy-n1 {
  --bs-gutter-y: -10px;
}

.g-n5,
.gx-n5 {
  --bs-gutter-x: -5px;
}

.g-n5,
.gy-n5 {
  --bs-gutter-y: -5px;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.6667rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.6667rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 1.3334rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 1.3334rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 2.0001rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 2.0001rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 2.6668rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 2.6668rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3.3335rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3.3335rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 4.0002rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 4.0002rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 4.6669rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 4.6669rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 5.3336rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 5.3336rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 6.0003rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 6.0003rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 6.667rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 6.667rem;
}

.g-gutter,
.gx-gutter {
  --bs-gutter-x: 2.133rem;
}

.g-gutter,
.gy-gutter {
  --bs-gutter-y: 2.133rem;
}

.g-out-container,
.gx-out-container {
  --bs-gutter-x: calc(50% - 50vw);
}

.g-out-container,
.gy-out-container {
  --bs-gutter-y: calc(50% - 50vw);
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #ed2224;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #be1b1d;
}
.nav-link.disabled {
  color: #d8d8d8;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #f0f4fa;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #f2f3f5 #f2f3f5 #f0f4fa;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #d8d8d8;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #f0f4fa #f0f4fa #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ed2224;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.33335rem;
  padding-bottom: 0.33335rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: -0.15625rem;
  padding-bottom: -0.15625rem;
  margin-right: 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: calc(1.3125rem + 0.75vw);
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.btn {
  display: inline-block;
  align-self: center;
  font-weight: 400;
  line-height: 1.5;
  color: #272626;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #272626;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(237, 34, 36, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #000000;
  background-color: #ed2224;
  border-color: #ed2224;
}
.btn-primary:hover {
  color: #000000;
  background-color: #f04345;
  border-color: #ef383a;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #000000;
  background-color: #f04345;
  border-color: #ef383a;
  box-shadow: 0 0 0 0.25rem rgba(201, 29, 31, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #000000;
  background-color: #f14e50;
  border-color: #ef383a;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(201, 29, 31, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #000000;
  background-color: #ed2224;
  border-color: #ed2224;
}

.btn-secondary {
  color: #000000;
  background-color: #b0c4c2;
  border-color: #b0c4c2;
}
.btn-secondary:hover {
  color: #000000;
  background-color: #bccdcb;
  border-color: #b8cac8;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000000;
  background-color: #bccdcb;
  border-color: #b8cac8;
  box-shadow: 0 0 0 0.25rem rgba(150, 167, 165, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #000000;
  background-color: #c0d0ce;
  border-color: #b8cac8;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(150, 167, 165, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000000;
  background-color: #b0c4c2;
  border-color: #b0c4c2;
}

.btn-light {
  color: #000000;
  background-color: #e8e2d7;
  border-color: #e8e2d7;
}
.btn-light:hover {
  color: #000000;
  background-color: #ebe6dd;
  border-color: #eae5db;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000000;
  background-color: #ebe6dd;
  border-color: #eae5db;
  box-shadow: 0 0 0 0.25rem rgba(197, 192, 183, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000000;
  background-color: #ede8df;
  border-color: #eae5db;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(197, 192, 183, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000000;
  background-color: #e8e2d7;
  border-color: #e8e2d7;
}

.btn-success {
  color: #000000;
  background-color: #2edba6;
  border-color: #2edba6;
}
.btn-success:hover {
  color: #000000;
  background-color: #4de0b3;
  border-color: #43dfaf;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #000000;
  background-color: #4de0b3;
  border-color: #43dfaf;
  box-shadow: 0 0 0 0.25rem rgba(39, 186, 141, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #000000;
  background-color: #58e2b8;
  border-color: #43dfaf;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(39, 186, 141, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #000000;
  background-color: #2edba6;
  border-color: #2edba6;
}

.btn-gray-100 {
  color: #000000;
  background-color: #f0f4fa;
  border-color: #f0f4fa;
}
.btn-gray-100:hover {
  color: #000000;
  background-color: #f2f6fb;
  border-color: #f2f5fb;
}
.btn-check:focus + .btn-gray-100, .btn-gray-100:focus {
  color: #000000;
  background-color: #f2f6fb;
  border-color: #f2f5fb;
  box-shadow: 0 0 0 0.25rem rgba(204, 207, 213, 0.5);
}
.btn-check:checked + .btn-gray-100, .btn-check:active + .btn-gray-100, .btn-gray-100:active, .btn-gray-100.active, .show > .btn-gray-100.dropdown-toggle {
  color: #000000;
  background-color: #f3f6fb;
  border-color: #f2f5fb;
}
.btn-check:checked + .btn-gray-100:focus, .btn-check:active + .btn-gray-100:focus, .btn-gray-100:active:focus, .btn-gray-100.active:focus, .show > .btn-gray-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(204, 207, 213, 0.5);
}
.btn-gray-100:disabled, .btn-gray-100.disabled {
  color: #000000;
  background-color: #f0f4fa;
  border-color: #f0f4fa;
}

.btn-gray-200 {
  color: #000000;
  background-color: #f2f3f5;
  border-color: #f2f3f5;
}
.btn-gray-200:hover {
  color: #000000;
  background-color: #f4f5f7;
  border-color: #f3f4f6;
}
.btn-check:focus + .btn-gray-200, .btn-gray-200:focus {
  color: #000000;
  background-color: #f4f5f7;
  border-color: #f3f4f6;
  box-shadow: 0 0 0 0.25rem rgba(206, 207, 208, 0.5);
}
.btn-check:checked + .btn-gray-200, .btn-check:active + .btn-gray-200, .btn-gray-200:active, .btn-gray-200.active, .show > .btn-gray-200.dropdown-toggle {
  color: #000000;
  background-color: #f5f5f7;
  border-color: #f3f4f6;
}
.btn-check:checked + .btn-gray-200:focus, .btn-check:active + .btn-gray-200:focus, .btn-gray-200:active:focus, .btn-gray-200.active:focus, .show > .btn-gray-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(206, 207, 208, 0.5);
}
.btn-gray-200:disabled, .btn-gray-200.disabled {
  color: #000000;
  background-color: #f2f3f5;
  border-color: #f2f3f5;
}

.btn-gray-300 {
  color: #000000;
  background-color: #f0f4fa;
  border-color: #f0f4fa;
}
.btn-gray-300:hover {
  color: #000000;
  background-color: #f2f6fb;
  border-color: #f2f5fb;
}
.btn-check:focus + .btn-gray-300, .btn-gray-300:focus {
  color: #000000;
  background-color: #f2f6fb;
  border-color: #f2f5fb;
  box-shadow: 0 0 0 0.25rem rgba(204, 207, 213, 0.5);
}
.btn-check:checked + .btn-gray-300, .btn-check:active + .btn-gray-300, .btn-gray-300:active, .btn-gray-300.active, .show > .btn-gray-300.dropdown-toggle {
  color: #000000;
  background-color: #f3f6fb;
  border-color: #f2f5fb;
}
.btn-check:checked + .btn-gray-300:focus, .btn-check:active + .btn-gray-300:focus, .btn-gray-300:active:focus, .btn-gray-300.active:focus, .show > .btn-gray-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(204, 207, 213, 0.5);
}
.btn-gray-300:disabled, .btn-gray-300.disabled {
  color: #000000;
  background-color: #f0f4fa;
  border-color: #f0f4fa;
}

.btn-gray-400 {
  color: #000000;
  background-color: #eff2f3;
  border-color: #eff2f3;
}
.btn-gray-400:hover {
  color: #000000;
  background-color: #f1f4f5;
  border-color: #f1f3f4;
}
.btn-check:focus + .btn-gray-400, .btn-gray-400:focus {
  color: #000000;
  background-color: #f1f4f5;
  border-color: #f1f3f4;
  box-shadow: 0 0 0 0.25rem rgba(203, 206, 207, 0.5);
}
.btn-check:checked + .btn-gray-400, .btn-check:active + .btn-gray-400, .btn-gray-400:active, .btn-gray-400.active, .show > .btn-gray-400.dropdown-toggle {
  color: #000000;
  background-color: #f2f5f5;
  border-color: #f1f3f4;
}
.btn-check:checked + .btn-gray-400:focus, .btn-check:active + .btn-gray-400:focus, .btn-gray-400:active:focus, .btn-gray-400.active:focus, .show > .btn-gray-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(203, 206, 207, 0.5);
}
.btn-gray-400:disabled, .btn-gray-400.disabled {
  color: #000000;
  background-color: #eff2f3;
  border-color: #eff2f3;
}

.btn-gray-500 {
  color: #000000;
  background-color: #768693;
  border-color: #768693;
}
.btn-gray-500:hover {
  color: #000000;
  background-color: #8b98a3;
  border-color: #84929e;
}
.btn-check:focus + .btn-gray-500, .btn-gray-500:focus {
  color: #000000;
  background-color: #8b98a3;
  border-color: #84929e;
  box-shadow: 0 0 0 0.25rem rgba(100, 114, 125, 0.5);
}
.btn-check:checked + .btn-gray-500, .btn-check:active + .btn-gray-500, .btn-gray-500:active, .btn-gray-500.active, .show > .btn-gray-500.dropdown-toggle {
  color: #000000;
  background-color: #919ea9;
  border-color: #84929e;
}
.btn-check:checked + .btn-gray-500:focus, .btn-check:active + .btn-gray-500:focus, .btn-gray-500:active:focus, .btn-gray-500.active:focus, .show > .btn-gray-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(100, 114, 125, 0.5);
}
.btn-gray-500:disabled, .btn-gray-500.disabled {
  color: #000000;
  background-color: #768693;
  border-color: #768693;
}

.btn-gray-600 {
  color: #000000;
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}
.btn-gray-600:hover {
  color: #000000;
  background-color: #dedede;
  border-color: gainsboro;
}
.btn-check:focus + .btn-gray-600, .btn-gray-600:focus {
  color: #000000;
  background-color: #dedede;
  border-color: gainsboro;
  box-shadow: 0 0 0 0.25rem rgba(184, 184, 184, 0.5);
}
.btn-check:checked + .btn-gray-600, .btn-check:active + .btn-gray-600, .btn-gray-600:active, .btn-gray-600.active, .show > .btn-gray-600.dropdown-toggle {
  color: #000000;
  background-color: #e0e0e0;
  border-color: gainsboro;
}
.btn-check:checked + .btn-gray-600:focus, .btn-check:active + .btn-gray-600:focus, .btn-gray-600:active:focus, .btn-gray-600.active:focus, .show > .btn-gray-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(184, 184, 184, 0.5);
}
.btn-gray-600:disabled, .btn-gray-600.disabled {
  color: #000000;
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}

.btn-gray-800 {
  color: #fff;
  background-color: #6f4e37;
  border-color: #6f4e37;
}
.btn-gray-800:hover {
  color: #fff;
  background-color: #5e422f;
  border-color: #593e2c;
}
.btn-check:focus + .btn-gray-800, .btn-gray-800:focus {
  color: #fff;
  background-color: #5e422f;
  border-color: #593e2c;
  box-shadow: 0 0 0 0.25rem rgba(133, 105, 85, 0.5);
}
.btn-check:checked + .btn-gray-800, .btn-check:active + .btn-gray-800, .btn-gray-800:active, .btn-gray-800.active, .show > .btn-gray-800.dropdown-toggle {
  color: #fff;
  background-color: #593e2c;
  border-color: #533b29;
}
.btn-check:checked + .btn-gray-800:focus, .btn-check:active + .btn-gray-800:focus, .btn-gray-800:active:focus, .btn-gray-800.active:focus, .show > .btn-gray-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(133, 105, 85, 0.5);
}
.btn-gray-800:disabled, .btn-gray-800.disabled {
  color: #fff;
  background-color: #6f4e37;
  border-color: #6f4e37;
}

.btn-gray-900 {
  color: #fff;
  background-color: #707070;
  border-color: #707070;
}
.btn-gray-900:hover {
  color: #fff;
  background-color: #5f5f5f;
  border-color: #5a5a5a;
}
.btn-check:focus + .btn-gray-900, .btn-gray-900:focus {
  color: #fff;
  background-color: #5f5f5f;
  border-color: #5a5a5a;
  box-shadow: 0 0 0 0.25rem rgba(133, 133, 133, 0.5);
}
.btn-check:checked + .btn-gray-900, .btn-check:active + .btn-gray-900, .btn-gray-900:active, .btn-gray-900.active, .show > .btn-gray-900.dropdown-toggle {
  color: #fff;
  background-color: #5a5a5a;
  border-color: #545454;
}
.btn-check:checked + .btn-gray-900:focus, .btn-check:active + .btn-gray-900:focus, .btn-gray-900:active:focus, .btn-gray-900.active:focus, .show > .btn-gray-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(133, 133, 133, 0.5);
}
.btn-gray-900:disabled, .btn-gray-900.disabled {
  color: #fff;
  background-color: #707070;
  border-color: #707070;
}

.btn-info {
  color: #000000;
  background-color: #21aaff;
  border-color: #21aaff;
}
.btn-info:hover {
  color: #000000;
  background-color: #42b7ff;
  border-color: #37b3ff;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000000;
  background-color: #42b7ff;
  border-color: #37b3ff;
  box-shadow: 0 0 0 0.25rem rgba(28, 145, 217, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000000;
  background-color: #4dbbff;
  border-color: #37b3ff;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(28, 145, 217, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000000;
  background-color: #21aaff;
  border-color: #21aaff;
}

.btn-warning {
  color: #000000;
  background-color: #ffc600;
  border-color: #ffc600;
}
.btn-warning:hover {
  color: #000000;
  background-color: #ffcf26;
  border-color: #ffcc1a;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000000;
  background-color: #ffcf26;
  border-color: #ffcc1a;
  box-shadow: 0 0 0 0.25rem rgba(217, 168, 0, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000000;
  background-color: #ffd133;
  border-color: #ffcc1a;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 168, 0, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000000;
  background-color: #ffc600;
  border-color: #ffc600;
}

.btn-danger {
  color: #fff;
  background-color: #a41a1f;
  border-color: #a41a1f;
}
.btn-danger:hover {
  color: #fff;
  background-color: #8b161a;
  border-color: #831519;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #8b161a;
  border-color: #831519;
  box-shadow: 0 0 0 0.25rem rgba(178, 60, 65, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #831519;
  border-color: #7b1417;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(178, 60, 65, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #a41a1f;
  border-color: #a41a1f;
}

.btn-dark {
  color: #000000;
  background-color: #b1b1b1;
  border-color: #b1b1b1;
}
.btn-dark:hover {
  color: #000000;
  background-color: #bdbdbd;
  border-color: #b9b9b9;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #000000;
  background-color: #bdbdbd;
  border-color: #b9b9b9;
  box-shadow: 0 0 0 0.25rem rgba(150, 150, 150, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #000000;
  background-color: #c1c1c1;
  border-color: #b9b9b9;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(150, 150, 150, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #000000;
  background-color: #b1b1b1;
  border-color: #b1b1b1;
}

.btn-black {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.btn-black:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:focus + .btn-black, .btn-black:focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-check:checked + .btn-black, .btn-check:active + .btn-black, .btn-black:active, .btn-black.active, .show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:checked + .btn-black:focus, .btn-check:active + .btn-black:focus, .btn-black:active:focus, .btn-black.active:focus, .show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-black:disabled, .btn-black.disabled {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.btn-red {
  color: #fff;
  background-color: #a41a1f;
  border-color: #a41a1f;
}
.btn-red:hover {
  color: #fff;
  background-color: #8b161a;
  border-color: #831519;
}
.btn-check:focus + .btn-red, .btn-red:focus {
  color: #fff;
  background-color: #8b161a;
  border-color: #831519;
  box-shadow: 0 0 0 0.25rem rgba(178, 60, 65, 0.5);
}
.btn-check:checked + .btn-red, .btn-check:active + .btn-red, .btn-red:active, .btn-red.active, .show > .btn-red.dropdown-toggle {
  color: #fff;
  background-color: #831519;
  border-color: #7b1417;
}
.btn-check:checked + .btn-red:focus, .btn-check:active + .btn-red:focus, .btn-red:active:focus, .btn-red.active:focus, .show > .btn-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(178, 60, 65, 0.5);
}
.btn-red:disabled, .btn-red.disabled {
  color: #fff;
  background-color: #a41a1f;
  border-color: #a41a1f;
}

.btn-orange {
  color: #000000;
  background-color: #ea630f;
  border-color: #ea630f;
}
.btn-orange:hover {
  color: #000000;
  background-color: #ed7a33;
  border-color: #ec7327;
}
.btn-check:focus + .btn-orange, .btn-orange:focus {
  color: #000000;
  background-color: #ed7a33;
  border-color: #ec7327;
  box-shadow: 0 0 0 0.25rem rgba(199, 84, 13, 0.5);
}
.btn-check:checked + .btn-orange, .btn-check:active + .btn-orange, .btn-orange:active, .btn-orange.active, .show > .btn-orange.dropdown-toggle {
  color: #000000;
  background-color: #ee823f;
  border-color: #ec7327;
}
.btn-check:checked + .btn-orange:focus, .btn-check:active + .btn-orange:focus, .btn-orange:active:focus, .btn-orange.active:focus, .show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(199, 84, 13, 0.5);
}
.btn-orange:disabled, .btn-orange.disabled {
  color: #000000;
  background-color: #ea630f;
  border-color: #ea630f;
}

.btn-outline-primary {
  color: #ed2224;
  border-color: #ed2224;
}
.btn-outline-primary:hover {
  color: #000000;
  background-color: #ed2224;
  border-color: #ed2224;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(237, 34, 36, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #000000;
  background-color: #ed2224;
  border-color: #ed2224;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(237, 34, 36, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #ed2224;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #b0c4c2;
  border-color: #b0c4c2;
}
.btn-outline-secondary:hover {
  color: #000000;
  background-color: #b0c4c2;
  border-color: #b0c4c2;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(176, 196, 194, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000000;
  background-color: #b0c4c2;
  border-color: #b0c4c2;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(176, 196, 194, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #b0c4c2;
  background-color: transparent;
}

.btn-outline-light {
  color: #e8e2d7;
  border-color: #e8e2d7;
}
.btn-outline-light:hover {
  color: #000000;
  background-color: #e8e2d7;
  border-color: #e8e2d7;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 226, 215, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000000;
  background-color: #e8e2d7;
  border-color: #e8e2d7;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 226, 215, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #e8e2d7;
  background-color: transparent;
}

.btn-outline-success {
  color: #2edba6;
  border-color: #2edba6;
}
.btn-outline-success:hover {
  color: #000000;
  background-color: #2edba6;
  border-color: #2edba6;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(46, 219, 166, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000000;
  background-color: #2edba6;
  border-color: #2edba6;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(46, 219, 166, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #2edba6;
  background-color: transparent;
}

.btn-outline-gray-100 {
  color: #f0f4fa;
  border-color: #f0f4fa;
}
.btn-outline-gray-100:hover {
  color: #000000;
  background-color: #f0f4fa;
  border-color: #f0f4fa;
}
.btn-check:focus + .btn-outline-gray-100, .btn-outline-gray-100:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 244, 250, 0.5);
}
.btn-check:checked + .btn-outline-gray-100, .btn-check:active + .btn-outline-gray-100, .btn-outline-gray-100:active, .btn-outline-gray-100.active, .btn-outline-gray-100.dropdown-toggle.show {
  color: #000000;
  background-color: #f0f4fa;
  border-color: #f0f4fa;
}
.btn-check:checked + .btn-outline-gray-100:focus, .btn-check:active + .btn-outline-gray-100:focus, .btn-outline-gray-100:active:focus, .btn-outline-gray-100.active:focus, .btn-outline-gray-100.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 244, 250, 0.5);
}
.btn-outline-gray-100:disabled, .btn-outline-gray-100.disabled {
  color: #f0f4fa;
  background-color: transparent;
}

.btn-outline-gray-200 {
  color: #f2f3f5;
  border-color: #f2f3f5;
}
.btn-outline-gray-200:hover {
  color: #000000;
  background-color: #f2f3f5;
  border-color: #f2f3f5;
}
.btn-check:focus + .btn-outline-gray-200, .btn-outline-gray-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(242, 243, 245, 0.5);
}
.btn-check:checked + .btn-outline-gray-200, .btn-check:active + .btn-outline-gray-200, .btn-outline-gray-200:active, .btn-outline-gray-200.active, .btn-outline-gray-200.dropdown-toggle.show {
  color: #000000;
  background-color: #f2f3f5;
  border-color: #f2f3f5;
}
.btn-check:checked + .btn-outline-gray-200:focus, .btn-check:active + .btn-outline-gray-200:focus, .btn-outline-gray-200:active:focus, .btn-outline-gray-200.active:focus, .btn-outline-gray-200.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(242, 243, 245, 0.5);
}
.btn-outline-gray-200:disabled, .btn-outline-gray-200.disabled {
  color: #f2f3f5;
  background-color: transparent;
}

.btn-outline-gray-300 {
  color: #f0f4fa;
  border-color: #f0f4fa;
}
.btn-outline-gray-300:hover {
  color: #000000;
  background-color: #f0f4fa;
  border-color: #f0f4fa;
}
.btn-check:focus + .btn-outline-gray-300, .btn-outline-gray-300:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 244, 250, 0.5);
}
.btn-check:checked + .btn-outline-gray-300, .btn-check:active + .btn-outline-gray-300, .btn-outline-gray-300:active, .btn-outline-gray-300.active, .btn-outline-gray-300.dropdown-toggle.show {
  color: #000000;
  background-color: #f0f4fa;
  border-color: #f0f4fa;
}
.btn-check:checked + .btn-outline-gray-300:focus, .btn-check:active + .btn-outline-gray-300:focus, .btn-outline-gray-300:active:focus, .btn-outline-gray-300.active:focus, .btn-outline-gray-300.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 244, 250, 0.5);
}
.btn-outline-gray-300:disabled, .btn-outline-gray-300.disabled {
  color: #f0f4fa;
  background-color: transparent;
}

.btn-outline-gray-400 {
  color: #eff2f3;
  border-color: #eff2f3;
}
.btn-outline-gray-400:hover {
  color: #000000;
  background-color: #eff2f3;
  border-color: #eff2f3;
}
.btn-check:focus + .btn-outline-gray-400, .btn-outline-gray-400:focus {
  box-shadow: 0 0 0 0.25rem rgba(239, 242, 243, 0.5);
}
.btn-check:checked + .btn-outline-gray-400, .btn-check:active + .btn-outline-gray-400, .btn-outline-gray-400:active, .btn-outline-gray-400.active, .btn-outline-gray-400.dropdown-toggle.show {
  color: #000000;
  background-color: #eff2f3;
  border-color: #eff2f3;
}
.btn-check:checked + .btn-outline-gray-400:focus, .btn-check:active + .btn-outline-gray-400:focus, .btn-outline-gray-400:active:focus, .btn-outline-gray-400.active:focus, .btn-outline-gray-400.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(239, 242, 243, 0.5);
}
.btn-outline-gray-400:disabled, .btn-outline-gray-400.disabled {
  color: #eff2f3;
  background-color: transparent;
}

.btn-outline-gray-500 {
  color: #768693;
  border-color: #768693;
}
.btn-outline-gray-500:hover {
  color: #000000;
  background-color: #768693;
  border-color: #768693;
}
.btn-check:focus + .btn-outline-gray-500, .btn-outline-gray-500:focus {
  box-shadow: 0 0 0 0.25rem rgba(118, 134, 147, 0.5);
}
.btn-check:checked + .btn-outline-gray-500, .btn-check:active + .btn-outline-gray-500, .btn-outline-gray-500:active, .btn-outline-gray-500.active, .btn-outline-gray-500.dropdown-toggle.show {
  color: #000000;
  background-color: #768693;
  border-color: #768693;
}
.btn-check:checked + .btn-outline-gray-500:focus, .btn-check:active + .btn-outline-gray-500:focus, .btn-outline-gray-500:active:focus, .btn-outline-gray-500.active:focus, .btn-outline-gray-500.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(118, 134, 147, 0.5);
}
.btn-outline-gray-500:disabled, .btn-outline-gray-500.disabled {
  color: #768693;
  background-color: transparent;
}

.btn-outline-gray-600 {
  color: #d8d8d8;
  border-color: #d8d8d8;
}
.btn-outline-gray-600:hover {
  color: #000000;
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}
.btn-check:focus + .btn-outline-gray-600, .btn-outline-gray-600:focus {
  box-shadow: 0 0 0 0.25rem rgba(216, 216, 216, 0.5);
}
.btn-check:checked + .btn-outline-gray-600, .btn-check:active + .btn-outline-gray-600, .btn-outline-gray-600:active, .btn-outline-gray-600.active, .btn-outline-gray-600.dropdown-toggle.show {
  color: #000000;
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}
.btn-check:checked + .btn-outline-gray-600:focus, .btn-check:active + .btn-outline-gray-600:focus, .btn-outline-gray-600:active:focus, .btn-outline-gray-600.active:focus, .btn-outline-gray-600.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(216, 216, 216, 0.5);
}
.btn-outline-gray-600:disabled, .btn-outline-gray-600.disabled {
  color: #d8d8d8;
  background-color: transparent;
}

.btn-outline-gray-800 {
  color: #6f4e37;
  border-color: #6f4e37;
}
.btn-outline-gray-800:hover {
  color: #fff;
  background-color: #6f4e37;
  border-color: #6f4e37;
}
.btn-check:focus + .btn-outline-gray-800, .btn-outline-gray-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(111, 78, 55, 0.5);
}
.btn-check:checked + .btn-outline-gray-800, .btn-check:active + .btn-outline-gray-800, .btn-outline-gray-800:active, .btn-outline-gray-800.active, .btn-outline-gray-800.dropdown-toggle.show {
  color: #fff;
  background-color: #6f4e37;
  border-color: #6f4e37;
}
.btn-check:checked + .btn-outline-gray-800:focus, .btn-check:active + .btn-outline-gray-800:focus, .btn-outline-gray-800:active:focus, .btn-outline-gray-800.active:focus, .btn-outline-gray-800.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(111, 78, 55, 0.5);
}
.btn-outline-gray-800:disabled, .btn-outline-gray-800.disabled {
  color: #6f4e37;
  background-color: transparent;
}

.btn-outline-gray-900 {
  color: #707070;
  border-color: #707070;
}
.btn-outline-gray-900:hover {
  color: #fff;
  background-color: #707070;
  border-color: #707070;
}
.btn-check:focus + .btn-outline-gray-900, .btn-outline-gray-900:focus {
  box-shadow: 0 0 0 0.25rem rgba(112, 112, 112, 0.5);
}
.btn-check:checked + .btn-outline-gray-900, .btn-check:active + .btn-outline-gray-900, .btn-outline-gray-900:active, .btn-outline-gray-900.active, .btn-outline-gray-900.dropdown-toggle.show {
  color: #fff;
  background-color: #707070;
  border-color: #707070;
}
.btn-check:checked + .btn-outline-gray-900:focus, .btn-check:active + .btn-outline-gray-900:focus, .btn-outline-gray-900:active:focus, .btn-outline-gray-900.active:focus, .btn-outline-gray-900.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(112, 112, 112, 0.5);
}
.btn-outline-gray-900:disabled, .btn-outline-gray-900.disabled {
  color: #707070;
  background-color: transparent;
}

.btn-outline-info {
  color: #21aaff;
  border-color: #21aaff;
}
.btn-outline-info:hover {
  color: #000000;
  background-color: #21aaff;
  border-color: #21aaff;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 170, 255, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000000;
  background-color: #21aaff;
  border-color: #21aaff;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 170, 255, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #21aaff;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc600;
  border-color: #ffc600;
}
.btn-outline-warning:hover {
  color: #000000;
  background-color: #ffc600;
  border-color: #ffc600;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 198, 0, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000000;
  background-color: #ffc600;
  border-color: #ffc600;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 198, 0, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc600;
  background-color: transparent;
}

.btn-outline-danger {
  color: #a41a1f;
  border-color: #a41a1f;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #a41a1f;
  border-color: #a41a1f;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(164, 26, 31, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #a41a1f;
  border-color: #a41a1f;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(164, 26, 31, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #a41a1f;
  background-color: transparent;
}

.btn-outline-dark {
  color: #b1b1b1;
  border-color: #b1b1b1;
}
.btn-outline-dark:hover {
  color: #000000;
  background-color: #b1b1b1;
  border-color: #b1b1b1;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(177, 177, 177, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #000000;
  background-color: #b1b1b1;
  border-color: #b1b1b1;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(177, 177, 177, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #b1b1b1;
  background-color: transparent;
}

.btn-outline-black {
  color: #000000;
  border-color: #000000;
}
.btn-outline-black:hover {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.btn-check:focus + .btn-outline-black, .btn-outline-black:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-check:checked + .btn-outline-black, .btn-check:active + .btn-outline-black, .btn-outline-black:active, .btn-outline-black.active, .btn-outline-black.dropdown-toggle.show {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.btn-check:checked + .btn-outline-black:focus, .btn-check:active + .btn-outline-black:focus, .btn-outline-black:active:focus, .btn-outline-black.active:focus, .btn-outline-black.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-outline-black:disabled, .btn-outline-black.disabled {
  color: #000000;
  background-color: transparent;
}

.btn-outline-red {
  color: #a41a1f;
  border-color: #a41a1f;
}
.btn-outline-red:hover {
  color: #fff;
  background-color: #a41a1f;
  border-color: #a41a1f;
}
.btn-check:focus + .btn-outline-red, .btn-outline-red:focus {
  box-shadow: 0 0 0 0.25rem rgba(164, 26, 31, 0.5);
}
.btn-check:checked + .btn-outline-red, .btn-check:active + .btn-outline-red, .btn-outline-red:active, .btn-outline-red.active, .btn-outline-red.dropdown-toggle.show {
  color: #fff;
  background-color: #a41a1f;
  border-color: #a41a1f;
}
.btn-check:checked + .btn-outline-red:focus, .btn-check:active + .btn-outline-red:focus, .btn-outline-red:active:focus, .btn-outline-red.active:focus, .btn-outline-red.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(164, 26, 31, 0.5);
}
.btn-outline-red:disabled, .btn-outline-red.disabled {
  color: #a41a1f;
  background-color: transparent;
}

.btn-outline-orange {
  color: #ea630f;
  border-color: #ea630f;
}
.btn-outline-orange:hover {
  color: #000000;
  background-color: #ea630f;
  border-color: #ea630f;
}
.btn-check:focus + .btn-outline-orange, .btn-outline-orange:focus {
  box-shadow: 0 0 0 0.25rem rgba(234, 99, 15, 0.5);
}
.btn-check:checked + .btn-outline-orange, .btn-check:active + .btn-outline-orange, .btn-outline-orange:active, .btn-outline-orange.active, .btn-outline-orange.dropdown-toggle.show {
  color: #000000;
  background-color: #ea630f;
  border-color: #ea630f;
}
.btn-check:checked + .btn-outline-orange:focus, .btn-check:active + .btn-outline-orange:focus, .btn-outline-orange:active:focus, .btn-outline-orange.active:focus, .btn-outline-orange.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(234, 99, 15, 0.5);
}
.btn-outline-orange:disabled, .btn-outline-orange.disabled {
  color: #ea630f;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #ed2224;
  text-decoration: underline;
}
.btn-link:hover {
  color: #be1b1d;
}
.btn-link:disabled, .btn-link.disabled {
  color: #d8d8d8;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0.6667rem 0.6667rem;
  border-bottom: 1px solid #d6d6d6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.33335rem 0.33335rem;
  margin: -0.33335rem -0.33335rem -0.33335rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.6667rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.4167rem;
  border-top: 1px solid #d6d6d6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(237, 34, 36, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: calc(1.3125rem + 0.75vw);
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #d8d8d8;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #272626;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #eff2f3;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #272626;
  background-color: #fff;
  border-color: #f69192;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(237, 34, 36, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder {
  color: #d8d8d8;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #d8d8d8;
  opacity: 1;
}
.form-control::placeholder {
  color: #d8d8d8;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #f2f3f5;
  opacity: 1;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
          margin-inline-end: 0.75rem;
  color: #272626;
  background-color: #f2f3f5;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
          margin-inline-end: 0.75rem;
  color: #272626;
  background-color: #f2f3f5;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e6e7e9;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e6e7e9;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
          margin-inline-end: 0.75rem;
  color: #272626;
  background-color: #f2f3f5;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e6e7e9;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #272626;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
          margin-inline-end: 0.5rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
          margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
          margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.3rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #272626;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236f4e37' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #eff2f3;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #f69192;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(237, 34, 36, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #f2f3f5;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #272626;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #f69192;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(237, 34, 36, 0.25);
}
.form-check-input:checked {
  background-color: #21aaff;
  border-color: #21aaff;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #ed2224;
  border-color: #ed2224;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f69192'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(237, 34, 36, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(237, 34, 36, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #ed2224;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #fabdbd;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #f0f4fa;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #ed2224;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #fabdbd;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #f0f4fa;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #768693;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #768693;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #272626;
  text-align: center;
  white-space: nowrap;
  background-color: #f2f3f5;
  border: 1px solid #eff2f3;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #2edba6;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.166675rem 0.33335rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000000;
  background-color: rgba(46, 219, 166, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #2edba6;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232edba6' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #2edba6;
  box-shadow: 0 0 0 0.25rem rgba(46, 219, 166, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #2edba6;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236f4e37' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232edba6' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #2edba6;
  box-shadow: 0 0 0 0.25rem rgba(46, 219, 166, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #2edba6;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #2edba6;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(46, 219, 166, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #2edba6;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #a41a1f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.166675rem 0.33335rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(164, 26, 31, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #a41a1f;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23a41a1f'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23a41a1f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #a41a1f;
  box-shadow: 0 0 0 0.25rem rgba(164, 26, 31, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #a41a1f;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236f4e37' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23a41a1f'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23a41a1f' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #a41a1f;
  box-shadow: 0 0 0 0.25rem rgba(164, 26, 31, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #a41a1f;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #a41a1f;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(164, 26, 31, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #a41a1f;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.header_logo img {
  max-width: 65px;
}
.header_menu {
  min-width: 77px;
}
.header_menu .headmenu-wrapper {
  left: -100%;
  transition: left 0.4s ease;
  padding-top: 110px;
}
.header_menu .headmenu-wrapper ul li {
  width: 100%;
  padding: 0 15px 15px;
}
.header_menu .headmenu-wrapper ul li.current-menu-item a {
  color: #ed2224;
}
.header_menu .headmenu-wrapper ul li a {
  color: #272626;
  text-transform: uppercase;
  font-family: var(--bs-font-sans-serif-quicksand-bold);
  font-size: 20px;
}
.header_search .search_toggle {
  display: none;
  position: absolute;
  top: 66px;
  width: 100%;
  left: 0;
  background: #b0c4c2;
}
.header_search .search_toggle form {
  display: flex;
  padding: 5px 0;
  position: relative;
}
.header_search .search_toggle form img {
  position: absolute;
  right: 0;
  filter: unset;
  top: 8px;
}
.header_search img {
  width: 33px;
  /* Safari/Chrome */
  filter: invert(100%);
}
.header_search .cart-count {
  top: 10px;
  left: 13px;
  font-size: 13px;
  font-family: var(--bs-font-sans-serif-quicksand-bold);
}

body.menu-open header .headmenu-wrapper {
  left: 0;
}

.search_toggle {
  display: none;
}
.search_toggle input[type=search] {
  width: 100%;
  border: none;
  background: transparent;
  padding: 0 15px 0 0;
  height: 40px;
  color: #fff;
}
.search_toggle input[type=search]::-moz-placeholder {
  color: #fff;
}
.search_toggle input[type=search]:-ms-input-placeholder {
  color: #fff;
}
.search_toggle input[type=search]::placeholder {
  color: #fff;
}
.search_toggle input[type=submit] {
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  font-size: 0;
  position: relative;
  z-index: 2;
}

.bar-wrapper {
  display: block;
  position: relative;
  width: 22px;
  height: 22px;
  z-index: 9999;
  margin-top: 3px;
}
.bar-wrapper span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #000000;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: all 0.25s ease;
}
.bar-wrapper span:first-child {
  top: 0;
}
.bar-wrapper span:nth-child(2) {
  top: 9px;
}
.bar-wrapper span:nth-child(3) {
  top: 18px;
}

body.menu-open header .header_menu .bar-wrapper span {
  height: 2px;
}
body.menu-open header .header_menu .bar-wrapper span:first-child {
  width: 100%;
  top: 9px;
  transform: rotate(135deg);
}
body.menu-open header .header_menu .bar-wrapper span:nth-child(2) {
  display: none;
}
body.menu-open header .header_menu .bar-wrapper span:nth-child(3) {
  width: 100%;
  top: 9px;
  transform: rotate(-135deg);
}
footer a {
  color: #fff !important;
}
footer a:hover {
  text-decoration: underline;
}
footer h3 {
  color: #fff;
}
footer ul li {
  padding-bottom: 10px;
}
footer .address ul i {
  padding-right: 5px;
}
footer .social {
  font-size: 20px;
}
footer .social a {
  color: #000000 !important;
}
footer .social span {
  width: 45px;
  height: 45px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/**
 * @package     Joomla.Site
 * @subpackage  Templates.redWEB
 *
 * @copyright   Copyright (C) 2020 redWEB. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * all-round-gothic:
 *   - http://typekit.com/eulas/00000000000000003b9b3766
 *   - http://typekit.com/eulas/00000000000000003b9b3767
 *   - http://typekit.com/eulas/00000000000000003b9b376c
 *   - http://typekit.com/eulas/00000000000000003b9b376d
 * raleway:
 *   - http://typekit.com/eulas/00000000000000003b9b13b9
 *   - http://typekit.com/eulas/00000000000000003b9b13b3
 *   - http://typekit.com/eulas/00000000000000003b9b13b2
 *   - http://typekit.com/eulas/00000000000000003b9b13b8
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-10-23 05:50:06 UTC"}*/
@font-face {
  font-family: "Roboto-Bold";
  src: url("root/fonts/Roboto-Bold.woff2") format("woff2"), url("root/fonts/Roboto-Bold.woff") format("woff"), url("root/fonts/Roboto-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("root/fonts/Roboto-Medium.woff2") format("woff2"), url("root/fonts/Roboto-Medium.woff") format("woff"), url("root/fonts/Roboto-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("root/fonts/Roboto-Regular.woff2") format("woff2"), url("root/fonts/Roboto-Regular.woff") format("woff"), url("root/fonts/Roboto-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Quicksand-Bold";
  src: url("root/fonts/Quicksand-Bold.woff2") format("woff2"), url("root/fonts/Quicksand-Bold.woff") format("woff"), url("root/fonts/Quicksand-Bold.ttf") format("truetype");
  font-display: swap;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.o-0 {
  opacity: 0 !important;
}

.o-25 {
  opacity: 0.25 !important;
}

.o-50 {
  opacity: 0.5 !important;
}

.o-75 {
  opacity: 0.75 !important;
}

.o-85 {
  opacity: 0.85 !important;
}

.o-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-25 {
  top: 25% !important;
}

.top-50 {
  top: 50% !important;
}

.top-75 {
  top: 75% !important;
}

.top-100 {
  top: 100% !important;
}

.top-10px {
  top: 10px !important;
}

.top-90px {
  top: 90px !important;
}

.top-n1 {
  top: -10px !important;
}

.top-n2 {
  top: -20px !important;
}

.top-3px {
  top: 3px !important;
}

.top-60px {
  top: 60px !important;
}

.top-70px {
  top: 70px !important;
}

.top-80px {
  top: 80px !important;
}

.top-100px {
  top: 100px !important;
}

.top-13px {
  top: 13px !important;
}

.top-15px {
  top: 15px !important;
}

.top-n3px {
  top: -3px !important;
}

.top-sm {
  top: 5px !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.bottom-10px {
  bottom: 10px !important;
}

.bottom-90px {
  bottom: 90px !important;
}

.bottom-n1 {
  bottom: -10px !important;
}

.bottom-n2 {
  bottom: -20px !important;
}

.bottom-3px {
  bottom: 3px !important;
}

.bottom-60px {
  bottom: 60px !important;
}

.bottom-70px {
  bottom: 70px !important;
}

.bottom-80px {
  bottom: 80px !important;
}

.bottom-100px {
  bottom: 100px !important;
}

.bottom-13px {
  bottom: 13px !important;
}

.bottom-15px {
  bottom: 15px !important;
}

.bottom-n3px {
  bottom: -3px !important;
}

.bottom-sm {
  bottom: 5px !important;
}

.start-0 {
  left: 0 !important;
}

.start-25 {
  left: 25% !important;
}

.start-50 {
  left: 50% !important;
}

.start-75 {
  left: 75% !important;
}

.start-100 {
  left: 100% !important;
}

.start-10px {
  left: 10px !important;
}

.start-90px {
  left: 90px !important;
}

.start-n1 {
  left: -10px !important;
}

.start-n2 {
  left: -20px !important;
}

.start-3px {
  left: 3px !important;
}

.start-60px {
  left: 60px !important;
}

.start-70px {
  left: 70px !important;
}

.start-80px {
  left: 80px !important;
}

.start-100px {
  left: 100px !important;
}

.start-13px {
  left: 13px !important;
}

.start-15px {
  left: 15px !important;
}

.start-n3px {
  left: -3px !important;
}

.start-sm {
  left: 5px !important;
}

.end-0 {
  right: 0 !important;
}

.end-25 {
  right: 25% !important;
}

.end-50 {
  right: 50% !important;
}

.end-75 {
  right: 75% !important;
}

.end-100 {
  right: 100% !important;
}

.end-10px {
  right: 10px !important;
}

.end-90px {
  right: 90px !important;
}

.end-n1 {
  right: -10px !important;
}

.end-n2 {
  right: -20px !important;
}

.end-3px {
  right: 3px !important;
}

.end-60px {
  right: 60px !important;
}

.end-70px {
  right: 70px !important;
}

.end-80px {
  right: 80px !important;
}

.end-100px {
  right: 100px !important;
}

.end-13px {
  right: 13px !important;
}

.end-15px {
  right: 15px !important;
}

.end-n3px {
  right: -3px !important;
}

.end-sm {
  right: 5px !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #d6d6d6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #d6d6d6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #d6d6d6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #d6d6d6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #d6d6d6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ed2224 !important;
}

.border-secondary {
  border-color: #b0c4c2 !important;
}

.border-light {
  border-color: #e8e2d7 !important;
}

.border-success {
  border-color: #2edba6 !important;
}

.border-gray-100 {
  border-color: #f0f4fa !important;
}

.border-gray-200 {
  border-color: #f2f3f5 !important;
}

.border-gray-300 {
  border-color: #f0f4fa !important;
}

.border-gray-400 {
  border-color: #eff2f3 !important;
}

.border-gray-500 {
  border-color: #768693 !important;
}

.border-gray-600 {
  border-color: #d8d8d8 !important;
}

.border-gray-800 {
  border-color: #6f4e37 !important;
}

.border-gray-900 {
  border-color: #707070 !important;
}

.border-info {
  border-color: #21aaff !important;
}

.border-warning {
  border-color: #ffc600 !important;
}

.border-danger {
  border-color: #a41a1f !important;
}

.border-dark {
  border-color: #b1b1b1 !important;
}

.border-black {
  border-color: #000000 !important;
}

.border-red {
  border-color: #a41a1f !important;
}

.border-orange {
  border-color: #ea630f !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-sm {
  width: 5px !important;
}

.w-20px {
  width: 20px !important;
}

.w-15px {
  width: 15px !important;
}

.w-25px {
  width: 25px !important;
}

.w-35px {
  width: 35px !important;
}

.w-40px {
  width: 40px !important;
}

.w-45px {
  width: 45px !important;
}

.w-60px {
  width: 60px !important;
}

.w-90px {
  width: 90px !important;
}

.w-100px {
  width: 100px !important;
}

.w-120px {
  width: 120px !important;
}

.w-150px {
  width: 150px !important;
}

.w-160px {
  width: 160px !important;
}

.w-200px {
  width: 200px !important;
}

.w-300px {
  width: 300px !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-85 {
  width: 85% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.maxw-100 {
  max-width: 100% !important;
}

.maxw-10 {
  max-width: 10% !important;
}

.maxw-15 {
  max-width: 15% !important;
}

.maxw-20 {
  max-width: 20% !important;
}

.maxw-30 {
  max-width: 30% !important;
}

.maxw-35 {
  max-width: 35% !important;
}

.maxw-40 {
  max-width: 40% !important;
}

.maxw-50 {
  max-width: 50% !important;
}

.maxw-60 {
  max-width: 60% !important;
}

.maxw-70 {
  max-width: 70% !important;
}

.maxw-85 {
  max-width: 85% !important;
}

.maxw-80 {
  max-width: 80% !important;
}

.maxw-90 {
  max-width: 90% !important;
}

.maxw-220 {
  max-width: 220px !important;
}

.maxw-400 {
  max-width: 400px !important;
}

.maxw-300 {
  max-width: 300px !important;
}

.maxw-700 {
  max-width: 700px !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-n40 {
  height: 40px !important;
}

.h-n49 {
  height: 49px !important;
}

.h-15px {
  height: 15px !important;
}

.h-n60 {
  height: 60px !important;
}

.h-n80 {
  height: 80px !important;
}

.h-50vh {
  height: 50vh !important;
}

.h-70vh {
  height: 70vh !important;
}

.h-480 {
  height: 480px !important;
}

.h-n120 {
  height: 120px !important;
}

.mh-350 {
  max-height: 350px !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-sm {
  gap: 0.33335rem !important;
}

.gap-3px {
  gap: 3px !important;
}

.gap-15px {
  gap: 15px !important;
}

.gap-25px {
  gap: 25px !important;
}

.gap-35px {
  gap: 35px !important;
}

.gap-n15 {
  gap: -15px !important;
}

.gap-n1 {
  gap: -10px !important;
}

.gap-n5 {
  gap: -5px !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.6667rem !important;
}

.gap-2 {
  gap: 1.3334rem !important;
}

.gap-3 {
  gap: 2.0001rem !important;
}

.gap-4 {
  gap: 2.6668rem !important;
}

.gap-5 {
  gap: 3.3335rem !important;
}

.gap-6 {
  gap: 4.0002rem !important;
}

.gap-7 {
  gap: 4.6669rem !important;
}

.gap-8 {
  gap: 5.3336rem !important;
}

.gap-9 {
  gap: 6.0003rem !important;
}

.gap-10 {
  gap: 6.667rem !important;
}

.gap-gutter {
  gap: 2.133rem !important;
}

.gap-out-container {
  gap: calc(50% - 50vw) !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-sm {
  margin: 0.33335rem !important;
}

.m-3px {
  margin: 3px !important;
}

.m-15px {
  margin: 15px !important;
}

.m-25px {
  margin: 25px !important;
}

.m-35px {
  margin: 35px !important;
}

.m-n15 {
  margin: -15px !important;
}

.m-n1 {
  margin: -10px !important;
}

.m-n5 {
  margin: -5px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.6667rem !important;
}

.m-2 {
  margin: 1.3334rem !important;
}

.m-3 {
  margin: 2.0001rem !important;
}

.m-4 {
  margin: 2.6668rem !important;
}

.m-5 {
  margin: 3.3335rem !important;
}

.m-6 {
  margin: 4.0002rem !important;
}

.m-7 {
  margin: 4.6669rem !important;
}

.m-8 {
  margin: 5.3336rem !important;
}

.m-9 {
  margin: 6.0003rem !important;
}

.m-10 {
  margin: 6.667rem !important;
}

.m-gutter {
  margin: 2.133rem !important;
}

.m-out-container {
  margin: calc(50% - 50vw) !important;
}

.m-auto {
  margin: auto !important;
}

.mx-sm {
  margin-right: 0.33335rem !important;
  margin-left: 0.33335rem !important;
}

.mx-3px {
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.mx-15px {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.mx-25px {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.mx-35px {
  margin-right: 35px !important;
  margin-left: 35px !important;
}

.mx-n15 {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.mx-n1 {
  margin-right: -10px !important;
  margin-left: -10px !important;
}

.mx-n5 {
  margin-right: -5px !important;
  margin-left: -5px !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.6667rem !important;
  margin-left: 0.6667rem !important;
}

.mx-2 {
  margin-right: 1.3334rem !important;
  margin-left: 1.3334rem !important;
}

.mx-3 {
  margin-right: 2.0001rem !important;
  margin-left: 2.0001rem !important;
}

.mx-4 {
  margin-right: 2.6668rem !important;
  margin-left: 2.6668rem !important;
}

.mx-5 {
  margin-right: 3.3335rem !important;
  margin-left: 3.3335rem !important;
}

.mx-6 {
  margin-right: 4.0002rem !important;
  margin-left: 4.0002rem !important;
}

.mx-7 {
  margin-right: 4.6669rem !important;
  margin-left: 4.6669rem !important;
}

.mx-8 {
  margin-right: 5.3336rem !important;
  margin-left: 5.3336rem !important;
}

.mx-9 {
  margin-right: 6.0003rem !important;
  margin-left: 6.0003rem !important;
}

.mx-10 {
  margin-right: 6.667rem !important;
  margin-left: 6.667rem !important;
}

.mx-gutter {
  margin-right: 2.133rem !important;
  margin-left: 2.133rem !important;
}

.mx-out-container {
  margin-right: calc(50% - 50vw) !important;
  margin-left: calc(50% - 50vw) !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-sm {
  margin-top: 0.33335rem !important;
  margin-bottom: 0.33335rem !important;
}

.my-3px {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.my-15px {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-25px {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-35px {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.my-n15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.my-n1 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.my-n5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.6667rem !important;
  margin-bottom: 0.6667rem !important;
}

.my-2 {
  margin-top: 1.3334rem !important;
  margin-bottom: 1.3334rem !important;
}

.my-3 {
  margin-top: 2.0001rem !important;
  margin-bottom: 2.0001rem !important;
}

.my-4 {
  margin-top: 2.6668rem !important;
  margin-bottom: 2.6668rem !important;
}

.my-5 {
  margin-top: 3.3335rem !important;
  margin-bottom: 3.3335rem !important;
}

.my-6 {
  margin-top: 4.0002rem !important;
  margin-bottom: 4.0002rem !important;
}

.my-7 {
  margin-top: 4.6669rem !important;
  margin-bottom: 4.6669rem !important;
}

.my-8 {
  margin-top: 5.3336rem !important;
  margin-bottom: 5.3336rem !important;
}

.my-9 {
  margin-top: 6.0003rem !important;
  margin-bottom: 6.0003rem !important;
}

.my-10 {
  margin-top: 6.667rem !important;
  margin-bottom: 6.667rem !important;
}

.my-gutter {
  margin-top: 2.133rem !important;
  margin-bottom: 2.133rem !important;
}

.my-out-container {
  margin-top: calc(50% - 50vw) !important;
  margin-bottom: calc(50% - 50vw) !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-sm {
  margin-top: 0.33335rem !important;
}

.mt-3px {
  margin-top: 3px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.mt-25px {
  margin-top: 25px !important;
}

.mt-35px {
  margin-top: 35px !important;
}

.mt-n15 {
  margin-top: -15px !important;
}

.mt-n1 {
  margin-top: -10px !important;
}

.mt-n5 {
  margin-top: -5px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.6667rem !important;
}

.mt-2 {
  margin-top: 1.3334rem !important;
}

.mt-3 {
  margin-top: 2.0001rem !important;
}

.mt-4 {
  margin-top: 2.6668rem !important;
}

.mt-5 {
  margin-top: 3.3335rem !important;
}

.mt-6 {
  margin-top: 4.0002rem !important;
}

.mt-7 {
  margin-top: 4.6669rem !important;
}

.mt-8 {
  margin-top: 5.3336rem !important;
}

.mt-9 {
  margin-top: 6.0003rem !important;
}

.mt-10 {
  margin-top: 6.667rem !important;
}

.mt-gutter {
  margin-top: 2.133rem !important;
}

.mt-out-container {
  margin-top: calc(50% - 50vw) !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-sm {
  margin-right: 0.33335rem !important;
}

.me-3px {
  margin-right: 3px !important;
}

.me-15px {
  margin-right: 15px !important;
}

.me-25px {
  margin-right: 25px !important;
}

.me-35px {
  margin-right: 35px !important;
}

.me-n15 {
  margin-right: -15px !important;
}

.me-n1 {
  margin-right: -10px !important;
}

.me-n5 {
  margin-right: -5px !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.6667rem !important;
}

.me-2 {
  margin-right: 1.3334rem !important;
}

.me-3 {
  margin-right: 2.0001rem !important;
}

.me-4 {
  margin-right: 2.6668rem !important;
}

.me-5 {
  margin-right: 3.3335rem !important;
}

.me-6 {
  margin-right: 4.0002rem !important;
}

.me-7 {
  margin-right: 4.6669rem !important;
}

.me-8 {
  margin-right: 5.3336rem !important;
}

.me-9 {
  margin-right: 6.0003rem !important;
}

.me-10 {
  margin-right: 6.667rem !important;
}

.me-gutter {
  margin-right: 2.133rem !important;
}

.me-out-container {
  margin-right: calc(50% - 50vw) !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-sm {
  margin-bottom: 0.33335rem !important;
}

.mb-3px {
  margin-bottom: 3px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.mb-25px {
  margin-bottom: 25px !important;
}

.mb-35px {
  margin-bottom: 35px !important;
}

.mb-n15 {
  margin-bottom: -15px !important;
}

.mb-n1 {
  margin-bottom: -10px !important;
}

.mb-n5 {
  margin-bottom: -5px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.6667rem !important;
}

.mb-2 {
  margin-bottom: 1.3334rem !important;
}

.mb-3 {
  margin-bottom: 2.0001rem !important;
}

.mb-4 {
  margin-bottom: 2.6668rem !important;
}

.mb-5 {
  margin-bottom: 3.3335rem !important;
}

.mb-6 {
  margin-bottom: 4.0002rem !important;
}

.mb-7 {
  margin-bottom: 4.6669rem !important;
}

.mb-8 {
  margin-bottom: 5.3336rem !important;
}

.mb-9 {
  margin-bottom: 6.0003rem !important;
}

.mb-10 {
  margin-bottom: 6.667rem !important;
}

.mb-gutter {
  margin-bottom: 2.133rem !important;
}

.mb-out-container {
  margin-bottom: calc(50% - 50vw) !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-sm {
  margin-left: 0.33335rem !important;
}

.ms-3px {
  margin-left: 3px !important;
}

.ms-15px {
  margin-left: 15px !important;
}

.ms-25px {
  margin-left: 25px !important;
}

.ms-35px {
  margin-left: 35px !important;
}

.ms-n15 {
  margin-left: -15px !important;
}

.ms-n1 {
  margin-left: -10px !important;
}

.ms-n5 {
  margin-left: -5px !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.6667rem !important;
}

.ms-2 {
  margin-left: 1.3334rem !important;
}

.ms-3 {
  margin-left: 2.0001rem !important;
}

.ms-4 {
  margin-left: 2.6668rem !important;
}

.ms-5 {
  margin-left: 3.3335rem !important;
}

.ms-6 {
  margin-left: 4.0002rem !important;
}

.ms-7 {
  margin-left: 4.6669rem !important;
}

.ms-8 {
  margin-left: 5.3336rem !important;
}

.ms-9 {
  margin-left: 6.0003rem !important;
}

.ms-10 {
  margin-left: 6.667rem !important;
}

.ms-gutter {
  margin-left: 2.133rem !important;
}

.ms-out-container {
  margin-left: calc(50% - 50vw) !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-sm {
  padding: 0.33335rem !important;
}

.p-3px {
  padding: 3px !important;
}

.p-15px {
  padding: 15px !important;
}

.p-25px {
  padding: 25px !important;
}

.p-35px {
  padding: 35px !important;
}

.p-n15 {
  padding: -15px !important;
}

.p-n1 {
  padding: -10px !important;
}

.p-n5 {
  padding: -5px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.6667rem !important;
}

.p-2 {
  padding: 1.3334rem !important;
}

.p-3 {
  padding: 2.0001rem !important;
}

.p-4 {
  padding: 2.6668rem !important;
}

.p-5 {
  padding: 3.3335rem !important;
}

.p-6 {
  padding: 4.0002rem !important;
}

.p-7 {
  padding: 4.6669rem !important;
}

.p-8 {
  padding: 5.3336rem !important;
}

.p-9 {
  padding: 6.0003rem !important;
}

.p-10 {
  padding: 6.667rem !important;
}

.p-gutter {
  padding: 2.133rem !important;
}

.p-out-container {
  padding: calc(50% - 50vw) !important;
}

.px-sm {
  padding-right: 0.33335rem !important;
  padding-left: 0.33335rem !important;
}

.px-3px {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

.px-15px {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.px-25px {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.px-35px {
  padding-right: 35px !important;
  padding-left: 35px !important;
}

.px-n15 {
  padding-right: -15px !important;
  padding-left: -15px !important;
}

.px-n1 {
  padding-right: -10px !important;
  padding-left: -10px !important;
}

.px-n5 {
  padding-right: -5px !important;
  padding-left: -5px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.6667rem !important;
  padding-left: 0.6667rem !important;
}

.px-2 {
  padding-right: 1.3334rem !important;
  padding-left: 1.3334rem !important;
}

.px-3 {
  padding-right: 2.0001rem !important;
  padding-left: 2.0001rem !important;
}

.px-4 {
  padding-right: 2.6668rem !important;
  padding-left: 2.6668rem !important;
}

.px-5 {
  padding-right: 3.3335rem !important;
  padding-left: 3.3335rem !important;
}

.px-6 {
  padding-right: 4.0002rem !important;
  padding-left: 4.0002rem !important;
}

.px-7 {
  padding-right: 4.6669rem !important;
  padding-left: 4.6669rem !important;
}

.px-8 {
  padding-right: 5.3336rem !important;
  padding-left: 5.3336rem !important;
}

.px-9 {
  padding-right: 6.0003rem !important;
  padding-left: 6.0003rem !important;
}

.px-10 {
  padding-right: 6.667rem !important;
  padding-left: 6.667rem !important;
}

.px-gutter {
  padding-right: 2.133rem !important;
  padding-left: 2.133rem !important;
}

.px-out-container {
  padding-right: calc(50% - 50vw) !important;
  padding-left: calc(50% - 50vw) !important;
}

.py-sm {
  padding-top: 0.33335rem !important;
  padding-bottom: 0.33335rem !important;
}

.py-3px {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.py-15px {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-25px {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py-35px {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.py-n15 {
  padding-top: -15px !important;
  padding-bottom: -15px !important;
}

.py-n1 {
  padding-top: -10px !important;
  padding-bottom: -10px !important;
}

.py-n5 {
  padding-top: -5px !important;
  padding-bottom: -5px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.6667rem !important;
  padding-bottom: 0.6667rem !important;
}

.py-2 {
  padding-top: 1.3334rem !important;
  padding-bottom: 1.3334rem !important;
}

.py-3 {
  padding-top: 2.0001rem !important;
  padding-bottom: 2.0001rem !important;
}

.py-4 {
  padding-top: 2.6668rem !important;
  padding-bottom: 2.6668rem !important;
}

.py-5 {
  padding-top: 3.3335rem !important;
  padding-bottom: 3.3335rem !important;
}

.py-6 {
  padding-top: 4.0002rem !important;
  padding-bottom: 4.0002rem !important;
}

.py-7 {
  padding-top: 4.6669rem !important;
  padding-bottom: 4.6669rem !important;
}

.py-8 {
  padding-top: 5.3336rem !important;
  padding-bottom: 5.3336rem !important;
}

.py-9 {
  padding-top: 6.0003rem !important;
  padding-bottom: 6.0003rem !important;
}

.py-10 {
  padding-top: 6.667rem !important;
  padding-bottom: 6.667rem !important;
}

.py-gutter {
  padding-top: 2.133rem !important;
  padding-bottom: 2.133rem !important;
}

.py-out-container {
  padding-top: calc(50% - 50vw) !important;
  padding-bottom: calc(50% - 50vw) !important;
}

.pt-sm {
  padding-top: 0.33335rem !important;
}

.pt-3px {
  padding-top: 3px !important;
}

.pt-15px {
  padding-top: 15px !important;
}

.pt-25px {
  padding-top: 25px !important;
}

.pt-35px {
  padding-top: 35px !important;
}

.pt-n15 {
  padding-top: -15px !important;
}

.pt-n1 {
  padding-top: -10px !important;
}

.pt-n5 {
  padding-top: -5px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.6667rem !important;
}

.pt-2 {
  padding-top: 1.3334rem !important;
}

.pt-3 {
  padding-top: 2.0001rem !important;
}

.pt-4 {
  padding-top: 2.6668rem !important;
}

.pt-5 {
  padding-top: 3.3335rem !important;
}

.pt-6 {
  padding-top: 4.0002rem !important;
}

.pt-7 {
  padding-top: 4.6669rem !important;
}

.pt-8 {
  padding-top: 5.3336rem !important;
}

.pt-9 {
  padding-top: 6.0003rem !important;
}

.pt-10 {
  padding-top: 6.667rem !important;
}

.pt-gutter {
  padding-top: 2.133rem !important;
}

.pt-out-container {
  padding-top: calc(50% - 50vw) !important;
}

.pe-sm {
  padding-right: 0.33335rem !important;
}

.pe-3px {
  padding-right: 3px !important;
}

.pe-15px {
  padding-right: 15px !important;
}

.pe-25px {
  padding-right: 25px !important;
}

.pe-35px {
  padding-right: 35px !important;
}

.pe-n15 {
  padding-right: -15px !important;
}

.pe-n1 {
  padding-right: -10px !important;
}

.pe-n5 {
  padding-right: -5px !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.6667rem !important;
}

.pe-2 {
  padding-right: 1.3334rem !important;
}

.pe-3 {
  padding-right: 2.0001rem !important;
}

.pe-4 {
  padding-right: 2.6668rem !important;
}

.pe-5 {
  padding-right: 3.3335rem !important;
}

.pe-6 {
  padding-right: 4.0002rem !important;
}

.pe-7 {
  padding-right: 4.6669rem !important;
}

.pe-8 {
  padding-right: 5.3336rem !important;
}

.pe-9 {
  padding-right: 6.0003rem !important;
}

.pe-10 {
  padding-right: 6.667rem !important;
}

.pe-gutter {
  padding-right: 2.133rem !important;
}

.pe-out-container {
  padding-right: calc(50% - 50vw) !important;
}

.pb-sm {
  padding-bottom: 0.33335rem !important;
}

.pb-3px {
  padding-bottom: 3px !important;
}

.pb-15px {
  padding-bottom: 15px !important;
}

.pb-25px {
  padding-bottom: 25px !important;
}

.pb-35px {
  padding-bottom: 35px !important;
}

.pb-n15 {
  padding-bottom: -15px !important;
}

.pb-n1 {
  padding-bottom: -10px !important;
}

.pb-n5 {
  padding-bottom: -5px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.6667rem !important;
}

.pb-2 {
  padding-bottom: 1.3334rem !important;
}

.pb-3 {
  padding-bottom: 2.0001rem !important;
}

.pb-4 {
  padding-bottom: 2.6668rem !important;
}

.pb-5 {
  padding-bottom: 3.3335rem !important;
}

.pb-6 {
  padding-bottom: 4.0002rem !important;
}

.pb-7 {
  padding-bottom: 4.6669rem !important;
}

.pb-8 {
  padding-bottom: 5.3336rem !important;
}

.pb-9 {
  padding-bottom: 6.0003rem !important;
}

.pb-10 {
  padding-bottom: 6.667rem !important;
}

.pb-gutter {
  padding-bottom: 2.133rem !important;
}

.pb-out-container {
  padding-bottom: calc(50% - 50vw) !important;
}

.ps-sm {
  padding-left: 0.33335rem !important;
}

.ps-3px {
  padding-left: 3px !important;
}

.ps-15px {
  padding-left: 15px !important;
}

.ps-25px {
  padding-left: 25px !important;
}

.ps-35px {
  padding-left: 35px !important;
}

.ps-n15 {
  padding-left: -15px !important;
}

.ps-n1 {
  padding-left: -10px !important;
}

.ps-n5 {
  padding-left: -5px !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.6667rem !important;
}

.ps-2 {
  padding-left: 1.3334rem !important;
}

.ps-3 {
  padding-left: 2.0001rem !important;
}

.ps-4 {
  padding-left: 2.6668rem !important;
}

.ps-5 {
  padding-left: 3.3335rem !important;
}

.ps-6 {
  padding-left: 4.0002rem !important;
}

.ps-7 {
  padding-left: 4.6669rem !important;
}

.ps-8 {
  padding-left: 5.3336rem !important;
}

.ps-9 {
  padding-left: 6.0003rem !important;
}

.ps-10 {
  padding-left: 6.667rem !important;
}

.ps-gutter {
  padding-left: 2.133rem !important;
}

.ps-out-container {
  padding-left: calc(50% - 50vw) !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.font-normal {
  font-family: var(--bs-font-sans-serif) !important;
}

.font-medium {
  font-family: var(--bs-font-sans-serif-medium) !important;
}

.font-bold {
  font-family: var(--bs-font-sans-serif-bold) !important;
}

.font-quicksand {
  font-family: var(--bs-font-sans-serif-quicksand-bold) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1.125rem !important;
}

.fs-7 {
  font-size: 1rem !important;
}

.fs-lg {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-sm {
  font-size: 0.875rem !important;
}

.fs-xs {
  font-size: 0.75rem !important;
}

.fs-xxs {
  font-size: 0.625rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-100 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-100-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-200 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-200-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-300-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-400-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-500 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-500-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-600 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-600-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-800 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-800-rgb), var(--bs-text-opacity)) !important;
}

.text-gray-900 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-900-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-rgb), var(--bs-text-opacity)) !important;
}

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #d8d8d8 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-100-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-200-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-300-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-400-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-500 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-500-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-600 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-600-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-800-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray-900 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-900-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 5px !important;
}

.rounded-2 {
  border-radius: 10px !important;
}

.rounded-3 {
  border-radius: 15px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important;
}

.rounded-start-2 {
  border-bottom-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.rounded-start-3 {
  border-bottom-left-radius: 15px !important;
  border-top-left-radius: 15px !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.fs-1 {
  font-size: 2.5rem !important;
}

.fs-2 {
  font-size: 2rem !important;
}

.fs-3 {
  font-size: 1.75rem !important;
}

.fs-4 {
  font-size: 1.5rem !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1.125rem !important;
}

.fs-7 {
  font-size: 1rem !important;
}

.fs-lg {
  font-size: 1.875rem !important;
}

.fs-sm {
  font-size: 0.875rem !important;
}

.fs-xs {
  font-size: 0.75rem !important;
}

.fs-xxs {
  font-size: 0.625rem !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.object-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-full {
  -o-object-fit: full !important;
     object-fit: full !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-md {
  z-index: 999 !important;
}

.z-lg {
  z-index: 1111 !important;
}

.z-xl {
  z-index: 9998 !important;
}

.z-xxl {
  z-index: 9999 !important;
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
* {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  min-height: 100%;
  position: relative;
  margin-top: 60px;
}
body.admin-bar header {
  top: 46px !important;
}

.loading-first {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 9999;
}
.loading-first .loader {
  position: absolute;
  top: 50%;
  left: calc(50% - 15px);
  transform: translate(-50%, -50%);
  -webkit-animation: loader4Animation 1s linear infinite;
          animation: loader4Animation 1s linear infinite;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000000;
}
.loading-first.hided {
  display: none;
}

@-webkit-keyframes loader4Animation {
  0% {
    background-color: #ed2224;
    box-shadow: 15px 0px 0px 0px rgba(237, 34, 36, 0.67), 30px 0px 0px 0px rgba(237, 34, 36, 0.33);
  }
  17% {
    background-color: rgba(237, 34, 36, 0.67);
    box-shadow: 15px 0px 0px 0px #ed2224, 30px 0px 0px 0px rgba(237, 34, 36, 0.67);
  }
  33% {
    background-color: rgba(237, 34, 36, 0.33);
    box-shadow: 15px 0px 0px 0px rgba(237, 34, 36, 0.67), 30px 0px 0px 0px #ed2224;
  }
  50% {
    background-color: rgba(237, 34, 36, 0);
    box-shadow: 15px 0px 0px 0px rgba(237, 34, 36, 0.33), 30px 0px 0px 0px rgba(24, 28, 34, 0.67);
  }
  67% {
    background-color: rgba(237, 34, 36, 0.33);
    box-shadow: 15px 0px 0px 0px rgba(237, 34, 36, 0), 30px 0px 0px 0px rgba(237, 34, 36, 0.33);
  }
  83% {
    background-color: rgba(237, 34, 36, 0.67);
    box-shadow: 15px 0px 0px 0px rgba(237, 34, 36, 0.33), 30px 0px 0px 0px rgba(237, 34, 36, 0);
  }
  100% {
    background-color: #ed2224;
    box-shadow: 15px 0px 0px 0px rgba(237, 34, 36, 0.67), 30px 0px 0px 0px rgba(237, 34, 36, 0.33);
  }
}

@keyframes loader4Animation {
  0% {
    background-color: #ed2224;
    box-shadow: 15px 0px 0px 0px rgba(237, 34, 36, 0.67), 30px 0px 0px 0px rgba(237, 34, 36, 0.33);
  }
  17% {
    background-color: rgba(237, 34, 36, 0.67);
    box-shadow: 15px 0px 0px 0px #ed2224, 30px 0px 0px 0px rgba(237, 34, 36, 0.67);
  }
  33% {
    background-color: rgba(237, 34, 36, 0.33);
    box-shadow: 15px 0px 0px 0px rgba(237, 34, 36, 0.67), 30px 0px 0px 0px #ed2224;
  }
  50% {
    background-color: rgba(237, 34, 36, 0);
    box-shadow: 15px 0px 0px 0px rgba(237, 34, 36, 0.33), 30px 0px 0px 0px rgba(24, 28, 34, 0.67);
  }
  67% {
    background-color: rgba(237, 34, 36, 0.33);
    box-shadow: 15px 0px 0px 0px rgba(237, 34, 36, 0), 30px 0px 0px 0px rgba(237, 34, 36, 0.33);
  }
  83% {
    background-color: rgba(237, 34, 36, 0.67);
    box-shadow: 15px 0px 0px 0px rgba(237, 34, 36, 0.33), 30px 0px 0px 0px rgba(237, 34, 36, 0);
  }
  100% {
    background-color: #ed2224;
    box-shadow: 15px 0px 0px 0px rgba(237, 34, 36, 0.67), 30px 0px 0px 0px rgba(237, 34, 36, 0.33);
  }
}
img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  transition: 0.3s;
  color: #272626;
}
a:hover, a:focus {
  color: #ed2224;
}

figcaption {
  text-align: center;
}

ul {
  padding: 0;
  list-style: none;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-family: "Quicksand-Bold", sans-serif;
  color: #000000;
}
h6 a,
.h6 a,
h5 a,
.h5 a,
h4 a,
.h4 a,
h3 a,
.h3 a,
h2 a,
.h2 a,
h1 a,
.h1 a {
  color: #000000;
  transition: 0.3s;
}
h6 a:hover,
.h6 a:hover,
h5 a:hover,
.h5 a:hover,
h4 a:hover,
.h4 a:hover,
h3 a:hover,
.h3 a:hover,
h2 a:hover,
.h2 a:hover,
h1 a:hover,
.h1 a:hover {
  color: #ed2224;
}

video:focus {
  outline: none;
}

figure {
  text-align: center;
}

.h-slider {
  height: calc(100vh - 60px);
  max-height: 500px;
}

.pager-list .st-pagelink {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pager-list .st-pagelink span,
.pager-list .st-pagelink a {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid #ed2224;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Quicksand-Bold", sans-serif;
}
.pager-list .st-pagelink span {
  background: #ed2224;
  color: #fff;
}
.pager-list .st-pagelink a {
  color: #ed2224;
}
.pager-list .st-pagelink img {
  width: 20px;
}

.btn-primary {
  color: #fff;
  border: 1px solid #ed2224;
  display: inline-block;
  text-align: center;
  padding: 10px 15px;
  border-radius: 5px;
  transition: 0.3s;
  font-family: "Quicksand-Bold", sans-serif;
  min-width: 140px;
}
.btn-primary:hover, .btn-primary:active {
  background-color: #fff;
  color: #ed2224;
  text-decoration: none;
}

.btn-secondary {
  color: #fff;
  background-color: #b0c4c2;
  border: 1px solid #b0c4c2;
  min-width: 170px;
  display: inline-block;
  text-align: center;
  padding: 9px;
  border-radius: 5px;
  transition: 0.3s;
}
.btn-secondary:hover, .btn-secondary:active {
  background-color: #fff;
  color: #b0c4c2;
  text-decoration: none;
}

.btn-white {
  font-family: "Quicksand-Bold", sans-serif;
  color: #fff;
  text-transform: uppercase;
  min-width: 160px;
  border-radius: 5px;
  background: transparent;
  border: none;
  padding: 12px 10px;
  border: 1px solid #fff;
  display: inline-block;
}
.btn-white:focus {
  box-shadow: none;
}
.btn-white:hover {
  background: #fff;
  color: #ed2224;
}

.btn-light {
  font-family: "Quicksand-Bold", sans-serif;
  color: #ed2224;
  text-transform: uppercase;
  min-width: 160px;
  border-radius: 5px;
  background: #e8e2d7;
  border: none;
  padding: 12px 10px;
}
.btn-light:focus {
  box-shadow: none;
}
.btn-light:hover {
  color: #b0c4c2;
}

.main_button {
  color: #ed2224;
  border: 1px solid #ed2224;
  display: inline-block;
  text-align: center;
  padding: 10px 15px;
  border-radius: 5px;
  transition: 0.3s;
  font-family: "Quicksand-Bold", sans-serif;
  min-width: 140px;
}
.main_button:hover, .main_button:active {
  background-color: #ed2224;
  color: #fff;
  text-decoration: none;
}

input:focus {
  outline: none;
}

.swiper-pagination {
  width: 100%;
  margin: 0;
  bottom: 5px !important;
}
.swiper-pagination .swiper-pagination-bullet {
  background-color: #ed2224;
  opacity: 0.5;
  margin: 0 3px;
}
.swiper-pagination .swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 23px !important;
  color: #a41a1f;
}

.swiper-button-prev {
  left: 10px !important;
}
.swiper-button-prev:focus {
  outline: none;
}

.swiper-button-next {
  right: 10px !important;
}
.swiper-button-next:focus {
  outline: none;
}

.custom_module .ugb-button {
  font-family: "Quicksand-Bold", sans-serif;
}
.custom_module.bg-01 {
  background-position: 150% center !important;
}

.overlay {
  position: relative;
}
.overlay:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

input[type=text],
input[type=email],
input[type=password],
input[type=tel] {
  height: 50px;
  width: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 0 15px;
  background-color: #fff;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=tel]:focus {
  outline: none;
}

textarea {
  height: 120px;
  width: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 15px;
  background-color: #fff;
  resize: none;
}
textarea:focus {
  outline: none;
}

input[type=checkbox],
input[type=radio] {
  position: absolute;
  opacity: 0;
}
input[type=checkbox]:hover,
input[type=radio]:hover {
  outline: none;
}
input[type=checkbox] + label, input[type=checkbox] + span,
input[type=radio] + label,
input[type=radio] + span {
  position: relative;
  cursor: pointer;
  padding: 0 0 0 30px;
}
input[type=checkbox] + label:focus, input[type=checkbox] + span:focus,
input[type=radio] + label:focus,
input[type=radio] + span:focus {
  outline: none;
}
input[type=checkbox] + label:before, input[type=checkbox] + span:before,
input[type=radio] + label:before,
input[type=radio] + span:before {
  content: "";
  display: inline-block;
  vertical-align: text-top;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #d6d6d6;
  position: absolute;
  left: 0;
}
input[type=checkbox] + span:before,
input[type=radio] + span:before {
  top: 0;
}
input[type=checkbox] + label:before,
input[type=radio] + label:before {
  top: 0px;
}
input[type=checkbox]:hover + label:before, input[type=checkbox]:hover + span:before,
input[type=radio]:hover + label:before,
input[type=radio]:hover + span:before {
  background: #fff;
}
input[type=checkbox]:checked + label:before, input[type=checkbox]:checked + span:before,
input[type=radio]:checked + label:before,
input[type=radio]:checked + span:before {
  background: #ed2224;
}
input[type=checkbox]:disabled + label, input[type=checkbox]:disabled + span,
input[type=radio]:disabled + label,
input[type=radio]:disabled + span {
  color: #fff;
  cursor: auto;
}
input[type=checkbox]:disabled + label:before, input[type=checkbox]:disabled + span:before,
input[type=radio]:disabled + label:before,
input[type=radio]:disabled + span:before {
  box-shadow: none;
  background: #fff;
}
input[type=checkbox]:checked + label:after, input[type=checkbox]:checked + span:after,
input[type=radio]:checked + label:after,
input[type=radio]:checked + span:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 11px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

input[type=radio] + span:before {
  border-radius: 50%;
}
input[type=radio]:checked + span:after {
  top: 10px;
}

select {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--bs-primary);
  background: var(--bs-white);
  color: var(--bs-body-color);
  padding: 0 15px 0 10px;
  margin-right: 0 !important;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: url("../images/down.svg") right center no-repeat;
  background-size: 15px;
}
select:focus-visible {
  outline: none;
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
  margin: 0 !important;
}

.show-password-input {
  display: none;
}

.site-main > nav.woocommerce-breadcrumb {
  display: none !important;
}

.woocommerce-breadcrumb {
  font-family: "Roboto-Bold", sans-serif;
}
.woocommerce-breadcrumb a {
  font-family: "Roboto-Regular", sans-serif;
}

.price del {
  color: #b0c4c2;
}
.price del .woocommerce-Price-amount {
  color: #b0c4c2;
}

.scroll_style {
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll_style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f0f4fa;
}
.scroll_style::-webkit-scrollbar {
  width: 7px;
  background-color: #f0f4fa;
}
.scroll_style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ed2224;
}

.btn-close {
  color: #ed2224 !important;
}

span.required {
  color: #a41a1f;
}

i:before {
  display: none;
}
.news_detail {
  padding: 87px 0 0;
  position: relative;
}
.news_detail:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 416px;
  background-color: #f0f4fa;
  left: 0;
  top: 0;
  z-index: -1;
}
.news_detail_ct {
  max-width: 708px;
  margin: 0 auto 60px;
  line-height: 1.7;
  position: relative;
}
.news_detail_ct ul {
  list-style: disc;
  padding-left: 17px;
}
.news_detail_ct ol {
  list-style: decimal;
  padding-left: 17px;
}
.news_detail_ct h2 {
  font-size: 24px;
  margin-bottom: 1rem;
}
.news_detail_share {
  position: absolute;
  left: -93px;
  top: 238px;
}
.news_detail_share .p01 {
  text-transform: uppercase;
  margin-bottom: 8px;
}
.news_detail_share .lst {
  padding-left: 0;
}
.news_detail_share .lst li {
  width: 48px;
  height: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
  margin-bottom: 16px;
  cursor: pointer;
}
.news_detail_share .lst li.facebook-share {
  background-color: #3b5998;
}
.news_detail_share .lst li.twitter-share {
  background-color: #1da1f2;
}
.news_detail_share .lst li.linkedIn-share {
  background-color: #2867b2;
}
.news_detail_block a {
  color: #d81f26 !important;
}
.news_detail_block a:hover {
  text-decoration: underline;
}
.news_detail_img {
  margin-bottom: 25px;
  text-align: center;
}
.news_detail_img img {
  border-radius: 20px;
}
.news_detail h1 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 15px;
}
.news_detail .date {
  text-align: center;
  margin-bottom: 35px;
  color: #ed2224;
}
.news_detail_comment {
  background-color: #f0f4fa;
  border-top: 1px solid #d6d6d6;
  padding: 45px 0 55px;
}
.news_detail_comment #comments {
  display: none;
}
.news_detail_comment .container {
  max-width: 738px;
}
.news_detail_comment h3 {
  font-size: 32px;
}
.news_detail_comment .form-submit input {
  background-color: #ed2224;
  color: #fff;
  height: 50px;
  padding: 11px 0 8px;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  width: 160px;
  display: inline-block;
  line-height: 1.5;
  border: none;
}
.news_detail_comment .form-submit input:focus {
  outline: none;
}
.news_detail_comment .form-submit input:hover {
  color: #fff;
}
.news_detail_comment .required {
  color: #a41a1f;
}
.news_detail_comment label {
  margin-bottom: 10px;
}
.news_detail_comment form {
  display: flex;
  flex-wrap: wrap;
}
.news_detail_comment .comment-notes {
  color: #ed2224;
  margin-bottom: 22px;
  width: 100%;
}
.news_detail_comment .comment-notes .required {
  color: #ed2224;
}
.news_detail_comment .comment-form-comment {
  width: 100%;
  margin-bottom: 9px;
}
.news_detail_comment .comment-form-author {
  margin-right: 30px;
}
.news_detail_comment .comment-form-author,
.news_detail_comment .comment-form-email {
  width: calc(50% - 15px);
}
.news_detail_comment footer {
  background-color: transparent;
  color: #ed2224;
}
.news_detail_comment footer .says,
.news_detail_comment footer .comment-metadata,
.news_detail_comment footer .comment-awaiting-moderation {
  display: none;
}
.news_detail .commentlist {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
.news_detail .commentlist li {
  list-style: none;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 12px;
  margin-bottom: 25px;
}
.news_detail .commentlist li .comment-author {
  margin-bottom: 18px;
}
.news_detail .commentlist li .comment-author img {
  width: 50px;
  border-radius: 50%;
  margin-right: 5px;
}
.news_detail .commentlist li .comment-author .fn {
  font-size: 18px;
}
.news_detail .commentlist li .comment-content {
  line-height: 1.7;
}
.news_detail .commentlist li .children {
  padding-left: 60px;
}
.news_detail .commentlist li .children li {
  border-bottom: none;
}
.news_detail .commentlist li .children li .comment-body {
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
}
.news_detail .commentlist li .children li .comment-body .comment-meta {
  width: 100%;
  text-transform: capitalize;
}
.news_detail .commentlist li .children li .comment-body .fn a {
  color: #a41a1f;
}
.news_detail .reply {
  display: none;
}
.news_detail_related {
  padding: 57px 0 35px 0;
}
.news_detail_related h3 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 45px;
}
.news_detail_related ul li figure {
  margin-bottom: 20px;
}
.news_detail_related ul li h4 {
  font-size: 24px;
  line-height: 1.5;
}

.main_banner img {
  max-height: 435px;
}

.mw-30 {
  width: 30px;
}

.w-250 {
  width: 250px;
}